/* General Styling */
.gallery-page {
  position: relative;
  min-height: 100vh;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-family: Arial, sans-serif;
}

/* Background Video */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.8;
}

/* Title Section */
.gallery-title {
  text-align: center;
  margin-top: 60px;
  font-size: 2.5rem;
  font-weight: bold;
}

/* Gallery Sections */
.gallery-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  max-width: 1200px;
  gap: 40px;
  padding: 20px;
}

.gallery-section.reverse-layout {
  flex-direction: row-reverse;
}

/* Image Styling */
.gallery-image img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.4s ease;
}

.gallery-image img:hover {
  transform: scale(1.05);
}

/* Text Styling */
.gallery-text {
  flex: 1;
  padding: 0 20px;
}

.gallery-text h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.gallery-text p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Fullscreen Modal */
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: modalFadeIn 0.3s ease;
}

/* Fullscreen Image */
.fullscreen-image {
  max-width: 90%;
  max-height: 90vh;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  animation: imageFadeIn 0.4s ease;
}

/* Close Button */
.close-button {
  position: fixed;
  top: 30px;
  right: 30px;
  background: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 28px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1001;
}

.close-button:hover {
  background: red;
  transform: rotate(90deg);
}

.close-icon {
  font-size: 24px;
  font-weight: bold;
}

@keyframes modalFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes imageFadeIn {
  from { 
    opacity: 0;
    transform: scale(0.9);
  }
  to { 
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-section {
    flex-direction: column;
  }

  .gallery-section.reverse-layout {
    flex-direction: column;
  }

  .gallery-text {
    text-align: center;
    padding: 10px;
  }

  .close-button {
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .gallery-title {
    font-size: 2rem;
  }

  .gallery-image img {
    max-width: 100%;
  }

  .gallery-text h2 {
    font-size: 1.5rem;
  }
}
