@tailwind base;
@tailwind components;
@tailwind utilities;

.delay-1 {
    animation-delay: 1s;
  }
  .delay-2 {
    animation-delay: 2s;
  }
  .delay-3 {
    animation-delay: 3s;
  }
  .delay-4 {
    animation-delay: 4s;
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideInUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate-fadeIn {
    animation: fadeIn 2s ease-in forwards;
  }
  
  .animate-slideInUp {
    animation: slideInUp 2s ease-out forwards;
  }
  
  